import React from 'react';
import './news.css'
import CustomNavbar1 from './Navbar1';
import Footer from './footer'
import { Chrono } from "react-chrono";

const News = () => {

  const items = [{
    title: "1",
    cardTitle: "Parveen Sultana Draws the Crowds on Day 6 of Saptak",
    cardDetailedText: "The first concert of the evening featured Alok and Abhiskek Lahiri on the sarod, accompanied by Sapan Anjaria and Tanay Rege on the tabla. The father-son duo began with Raag Jog and ended with Raag Charukeshi in an enthralling 80-minute performance. The powerful sound of two sarods playing in harmony (along with two sets of tablas) made for some truly joyous music.",
    cardSubtitle: <a style={{color: '#490648',}} href="https://www.vibesofindia.com/parveen-sultana-draws-the-crowds-on-day-6-of-saptak/" target="_blank" rel="noopener noreferrer">Read more</a>,
  }, {
    title: "2",
    cardTitle: "Surmandal's Tribute to the Legends of Hindustani Classical Music",
    cardSubtitle: <a style={{color: '#490648',}} href="https://www.deccanchronicle.com/entertainment/music/surmandals-tribute-to-the-legends-of-hindustani-classical-music-887290" target="_blank" rel="noopener noreferrer">Read more</a>
  }, {
    title: "3",
    cardTitle: "Fusion on feet: Flamenco and Kathak meet at Prithvi Theatre",
    cardDetailedText: "The 90-minute performance will start with solos of the individual forms before they meld on stage to live music. “Flamenco-Kathaa is a story of how two dance forms can be merged together, rhythmically and musically,” says Bhagwat. The live dance performance will see Aditya Kudtarkar on Cajon and Darbuka percussion, Shruti Bhave on violin, Tanay Rege on tabla and Anubhav Sharma on the vocals. ",
    cardSubtitle: <a style={{color: '#490648',}} href="https://www.hindustantimes.com/mumbai-news/fusion-on-feet-flamenco-and-kathak-meet-at-prithvi-theatre/story-whOFpqg3ls6wyzM9PlJGcN.html" target="_blank" rel="noopener noreferrer">Read more</a>
  }, {
    title: "4",
    cardTitle: "27th OMC Guru Kelucharan Mohapatra Award Festival proved to be visual & auditory delight to audiences",
    cardDetailedText: "27TH OMC GURU KELUCHARAN MOHAPATRA AWARD FESTIVAL PROVED TO BE VISUAL & AUDITORY DELIGHT TO AUDIENCES. Ably accompanied on the Tabla by Tanay Rege, on vocals, by Aditya Apte, and on the harmonium by Omkar Agnihotri, Avartan was a dedication to the Gurus, presented with utmost commitment and elan by the young dancer from Mumbai.",
    cardSubtitle: <a style={{color: '#490648',}} href="https://www.odisharay.com/pages/single_page.php?id=28294">Read more</a>
  },
  {
    title: "5",
    cardTitle: "Pune: Talented artists from world of Indian Classical Musicians to perform at Swaraunmesh",
    cardSubtitle: <a style={{color: '#490648',}} href="https://www.punekarnews.in/pune-talented-artists-from-world-of-indian-classical-musicians-to-perform-at-swaraunmesh/">Read more</a>
  },
  {
    title: "6",
    cardTitle: "Ganga Cauvery to enchant Doha music enthusiasts on July 20",
    cardDetailedText: "DOHA: Music enthusiasts in Doha are welcome to a fusion of Hindustani and Carnatic musical traditions “Ganga Cauvery” concert on Saturday, July 20. Purbayan and Ambi prepares to enchant audiences with Tanay Rege on Tabla.",
    cardSubtitle: <a style={{color: '#490648',}} href="https://thepeninsulaqatar.com/article/18/07/2024/ganga-cauvery-to-enchant-doha-music-enthusiasts-on-july-20">Read more</a>
  },
];

  const reverseChrono = items => [...items];

  return (
    <div style={{fontFamily: 'Maven Pro'}}>    
      <CustomNavbar1 />
        <div className="background_news"></div>
        <div style={{paddingTop: '5em'}}>
          <h2 style={{textAlign: 'center', fontSize: '2.8em', fontWeight: '800'}}>News & Updates</h2>
          <div>
            <div className="news-div">
                <div style={{ width: '100%', height: 'auto' }}>
                    <Chrono 
                        items={reverseChrono(items)} 
                        theme={{
                            primary: '#490648',
                            secondary: '#490648',
                            cardBgColor: 'white',
                            titleColor: '#490648',
                            titleColorActive: 'white',
                            cardTitleColor: '#490648'
                        }} 
                        mode="VERTICAL" 
                        slideShow
                        slideItemDuration={4500}
                        slideShowType="reveal"
                        hideControls 
                    />
                </div>
            </div>
          </div>
        </div>
      <Footer />
    </div>
  );
};

export default News;