import React, { useState } from 'react';
import './Navbar.css'; // Import your CSS file
import { Navbar, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faInstagram,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';

const CustomNavbar1 = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const toggleOffcanvas = () => {
    setShowOffcanvas(prev => !prev);
  };

  return (
    <>
      <Navbar expand="lg" className="custom-navbar" style={{padding: '1.5em 0', backgroundColor: '#490648'}}>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleOffcanvas} style={{marginLeft: '1em', backgroundColor: '#fff'}}/>
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content nav-links-1">
          <Nav className='nav-links'>
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/about">About</Nav.Link>
            <Nav.Link href="/learn-with-me">Learn With Tanay</Nav.Link>
            <Nav.Link href="/news">News</Nav.Link>
            <Nav.Link href="/contact">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end nav-links-2">
          <Nav>
              <div>
                <a href="https://www.instagram.com/tanay_tabla?igsh=MW1nNzloNGh5ZXRpOQ==" target='_blank'><FontAwesomeIcon icon={faInstagram} className='social-link'/></a>
                <a href="https://m.facebook.com/tanay.rege?mibextid=LQQJ4d" target='_blank'><FontAwesomeIcon icon={faFacebook} className='social-link'/></a>
                <a href="https://youtube.com/@TanayRegeOfficial?si=HiZejbt5f5YCdcXa" target='_blank'><FontAwesomeIcon icon={faYoutube} className='social-link'/></a>
              </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default CustomNavbar1;