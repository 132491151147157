import React from 'react';
import './news.css'
import CustomNavbar1 from './Navbar1';
import Footer from './footer'
import {
    MDBBtn,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBInput
  }
from 'mdb-react-ui-kit';

const LearnWithMe = () => {

  return (
    <div style={{fontFamily: 'Maven Pro', color: '#fff', backgroundColor: '#490648'}}>    
      <CustomNavbar1 />
        <div className="background_news"></div>
        <div style={{paddingTop: '5em'}}>
          <h2 style={{textAlign: 'center', fontSize: '2.8em', fontWeight: '800'}}>Learn With Me</h2>
          <div>
                <MDBContainer fluid className='d-flex align-items-center justify-content-center bg-image' style={{backgroundColor: '#490648', padding: '2em 0'}}>
                    <div className='mask gradient-custom-3'></div>
                    <MDBCard className='m-5' style={{maxWidth: '90%', backgroundColor: '#fff', padding: '1em 1em', borderRadius: '20px', minWidth: '40%'}}>
                        <MDBCardBody className='px-5'>
                        <h2 className="text-uppercase text-center mb-5" style={{fontFamily: 'Maven Pro', fontWeight: '900'}}>Contact Me</h2>
                        <MDBInput
                        wrapperClass='mb-4'
                        label={<span style={{ color: '#362009', fontFamily: 'Maven Pro', fontWeight: '600' }}>Your Name</span>}
                        size='lg'
                        id='form1'
                        type='text'
                        style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            borderBottom: '1px solid #362009', 
                            borderRadius: '0',
                            boxShadow: 'none',
                            padding: '10px 0',
                            fontSize: '0.8em',
                        }}
                        />
                        <MDBInput
                        wrapperClass='mb-4'
                        label={<span style={{ color: '#362009', fontFamily: 'Maven Pro', fontWeight: '600' }}>Your Email</span>}
                        size='lg'
                        id='form2'
                        type='email'
                        style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            borderBottom: '1px solid black', 
                            borderRadius: 0,
                            boxShadow: 'none',
                            padding: '3px',
                            fontSize: '0.8em'
                        }}
                        />
                        <MDBInput
                        wrapperClass='mb-4'
                        label={<span style={{ color: '#362009', fontFamily: 'Maven Pro', fontWeight: '600' }}>Your Contact Number</span>}
                        size='lg'
                        id='form3'
                        type='text'
                        style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            borderBottom: '1px solid black', 
                            borderRadius: 0,
                            boxShadow: 'none',
                            padding: '5px 0',
                            fontSize: '0.8em'
                        }}
                        />
                        <MDBInput
                        wrapperClass='mb-4'
                        label={<span style={{ color: '#362009', fontFamily: 'Maven Pro', fontWeight: '600' }}>Your Address</span>}
                        size='lg'
                        id='form3'
                        type='text'
                        style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            borderBottom: '1px solid black', 
                            borderRadius: 0,
                            boxShadow: 'none',
                            padding: '5px 0',
                            fontSize: '0.8em'
                        }}
                        />
                        <MDBInput
                        wrapperClass='mb-4'
                        label={<span style={{ color: '#362009', fontFamily: 'Maven Pro', fontWeight: '600' }}>Your Profession</span>}
                        size='lg'
                        id='form3'
                        type='text'
                        style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            borderBottom: '1px solid black', 
                            borderRadius: 0,
                            boxShadow: 'none',
                            padding: '5px 0',
                            fontSize: '0.8em'
                        }}
                        />
                        <div className="button-div">
                            <MDBBtn className='button-87' size='lg'>Reach Out</MDBBtn>
                        </div>
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </div>
        </div>
      <Footer />
    </div>
  );
};

export default LearnWithMe;