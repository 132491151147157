import React from "react";
import CustomNavbar1 from "./Navbar1";
import './contact.css'
import Footer from "./footer";
import {
    MDBBtn,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBInput
  }
from 'mdb-react-ui-kit';

function Contact () {

    const currentImageIndex = 0;

    return (
        <div>
            <CustomNavbar1 />
            <div>
                <MDBContainer fluid className='d-flex align-items-center justify-content-center bg-image' style={{backgroundColor: '#490648', padding: '2em 0'}}>
                    <div className='mask gradient-custom-3'></div>
                    <MDBCard className='m-5' style={{maxWidth: '70%', backgroundColor: '#fff', padding: '1em 2em', borderRadius: '20px'}}>
                        <MDBCardBody className='px-5'>
                        <h2 className="text-uppercase text-center mb-5" style={{fontFamily: 'Maven Pro', fontWeight: '900'}}>Contact Me</h2>
                        <MDBInput
                        wrapperClass='mb-4'
                        label={<span style={{ color: '#362009', fontFamily: 'Maven Pro', fontWeight: '600' }}>Your Name</span>}
                        size='lg'
                        id='form1'
                        type='text'
                        style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            borderBottom: '1px solid #362009', 
                            borderRadius: '0',
                            boxShadow: 'none',
                            padding: '10px 0',
                            fontSize: '0.8em',
                        }}
                        />
                        <MDBInput
                        wrapperClass='mb-4'
                        label={<span style={{ color: '#362009', fontFamily: 'Maven Pro', fontWeight: '600' }}>Your Email</span>}
                        size='lg'
                        id='form2'
                        type='email'
                        style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            borderBottom: '1px solid black', 
                            borderRadius: 0,
                            boxShadow: 'none',
                            padding: '3px',
                            fontSize: '0.8em'
                        }}
                        />
                        <MDBInput
                        wrapperClass='mb-4'
                        label={<span style={{ color: '#362009', fontFamily: 'Maven Pro', fontWeight: '600' }}>Your Message</span>}
                        size='lg'
                        id='form3'
                        type='text'
                        style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            borderBottom: '1px solid black', 
                            borderRadius: 0,
                            boxShadow: 'none',
                            padding: '5px 0',
                            fontSize: '0.8em'
                        }}
                        />
                        <div className="button-div">
                            <MDBBtn className='button-87' size='lg'>Reach Out</MDBBtn>
                        </div>
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </div>
            <Footer />
        </div>
    )
}

export default Contact